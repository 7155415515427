import {gql, useQuery} from "@apollo/client";
import {MeetingRoom} from "../API";
import {useEffect, useMemo} from "react";
import {getMeetingRoomsByBuildingId} from "../graphql/queries";
import {onUpdateSecureMeetingRoom} from "../graphql/subscriptions";
import {MeetingRoomInfoWithCapacity} from "../types/MeetingRoomBookingListItemType";
import {meetingRoomIdComparator, toMeetingRoomToInfoWithCapacity} from "../Utils/svgPlans/meetingRooms";

const EMPTY_ARRAY: MeetingRoom[] = [];

export function useMeetingRoomInfoList(buildingId?: string, roomIds?: string[]): [MeetingRoomInfoWithCapacity[], () => void, boolean] {
    // TODO does roomIds = undefined/null mean "all rooms meetingrooms" and we should fetch data or should we skip and wait for roomIds?
    // now: undefined/null => all rooms of building, [] => no rooms

    const {data, subscribeToMore, refetch, loading} = useQuery(gql(getMeetingRoomsByBuildingId), {
        variables: {buildingId: buildingId},
        skip: !buildingId || roomIds?.length === 0,
    });

    useEffect(() => {
        if (!buildingId || roomIds?.length === 0) {
            return;
        }

        const unsubscribeFromUpdate = subscribeToMore({
            document: gql(onUpdateSecureMeetingRoom),
            variables: {},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) {
                    return prev;
                }
                const updatedMeetingRoom = subscriptionData.data.onUpdateSecureMeetingRoom;

                const existingItems = prev.getMeetingRoomsByBuildingId;
                let updatedItems = existingItems.map((item: MeetingRoom) =>
                    item.meetingRoomId === updatedMeetingRoom.meetingRoomId ? updatedMeetingRoom : item
                );

                if (!existingItems.find((item: MeetingRoom) => item.meetingRoomId === updatedMeetingRoom.meetingRoomId)) {
                    updatedItems = [...updatedItems, updatedMeetingRoom];
                }

                return {
                    ...prev,
                    getMeetingRoomsByBuildingId: updatedItems,
                };
            },
            onError: (error: any) => console.error("useMeetingRoomList useEffect:subscribeToMore:onUpdateSecureMeetingRoom " + JSON.stringify(error)),
        });

        return () => {
            unsubscribeFromUpdate();
        };
    }, [subscribeToMore, buildingId]);

    const filteredMeetingRooms: MeetingRoomInfoWithCapacity[] = useMemo(() => {
        if (roomIds?.length === 0) {
            return EMPTY_ARRAY;
        }
        const result = (data?.getMeetingRoomsByBuildingId ?? [])
            .filter((mr: MeetingRoom) => !roomIds || roomIds.includes(mr.roomId))   //  return meetingroom data of all rooms of building, when roomIds is undefined/null
            .sort(meetingRoomIdComparator)
            .map(toMeetingRoomToInfoWithCapacity)

        if (result.length === 0){
            return EMPTY_ARRAY;
        }
        return result;
    }, [roomIds]);

    return [filteredMeetingRooms, refetch, loading];
}
